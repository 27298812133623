<template>
  <div :style="{
    margin: '32px 0',
    height: '160px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }">
    <van-loading size="24" vertical>加载中...</van-loading>
  </div>
</template>

<script>
import {Loading} from 'vant'
export default {
  name: 'Loading',
  components: {
    [Loading.name]: Loading
  },
  methods: {
    reatyClickHandle() {
      this.$emit('reaty')
    }
  }
}
</script>

<style lang="scss" scoped>
  .bottom-button {
    width: 160px;
    height: 40px;
  }
</style>