/**
 * 综合查询相关接口
 * 微服务： 综合查询(psdmszhcx)
 */

import fetch from '@/utils/fetch';

/**
 * 根据用户编号查询用电户基本信息
 * @param {*} yhbh 
 * @param {*} meta 
 */
export function yhJbxx_fetchOneYhJbxxByYhbh(yhbh, meta) {
	return fetch({
		url : 'psdmszhcx/zhcxschema/YhJbxx/fetchOneYhJbxxByYhbh',
		method : 'post',
		data : {
			param : {
				yhbh : yhbh //String
			}
		}
	})
}

/* ---
用户纳税信息查询
标准CRUD方法：条件查询
*/
export function yhNsxx_selectiveGetOne(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhNsxx/selectiveGetOne',
		method : 'post',
		data : {
			param : entity //YhNsxx
		}
	})
}


/**
 * 查询简易账单   杨燕武
 * @param {*} nf 
 * @param {*} jgbm 
 * @param {*} yhbh 
 * @param {*} pagination 
 * @param {*} meta 
 */
export function zwYhzdxx_getYhjyzdByYhbhOrNf(nf,jgbm,yhbh, pagination, meta) {
	return fetch({
		url : 'psdmszhcx/zhcxschema/ZwYhzdxx/getYhjyzdByYhbhOrNf',
		method : 'post',
		data : {
			param : {
				nf : nf, //List<Integer>
				jgbm : jgbm, //String
				yhbh : yhbh //String
			},
			pagination : pagination
		}
	})
}
