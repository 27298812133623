<template>
  <div :style="{textAlign: 'center'}" class="yhbh-empty">
    <svg-icon icon-class="alert-circle" size="64" color="#E6A23C"/>
    <h2 class="yhbh-empty__title">温馨提示</h2>
    <p class="yhbh-empty__desc">为保证您的用电信息安全，此项业务需绑定用户编号。</p>
    <van-button class="yhbh-empty__btn" type="primary" :to="{path: '/bind-yhbh', query: {fromPage: fromPage}}">绑定用户编号</van-button>
  </div>
</template>

<script>
export default {
  name: 'YhbhEmpty',
  props: {
    fromPage: {
      type: String,
      default: '/',
      required: true
    }
  },
  data() {
    return {
      
    }
  },
}
</script>

<style lang="scss" scoped>
  @import 'src/styles/variable.scss';
  .yhbh-empty {
    text-align: center;
    margin: 32px 16px;
  }

  .yhbh-empty__title {
    font-size: 16px;
    color: #000;
    margin: 16px 0;
  }
  .yhbh-empty__desc {
    font-size: 14px;
    color: $color-gray-300;
    margin-bottom: 16px;
  }
  .yhbh-empty__btn{
    width: 160px;
    height: 40px;
  }
</style>